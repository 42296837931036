/* Custom CSS for Modal */
.modal {
  display: flex !important; /* Ensure modal is displayed as flex container */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.modal-dialog {
  max-width: 600px; /* Adjust max-width as needed */
  margin: auto; /* Center horizontally */
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  z-index: 1050; /* Ensure it's above the modal */
}